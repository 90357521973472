import React from "react";
import { StyleSheet, Text, Image, View } from "@react-pdf/renderer";
import moment from "moment";

export const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
  },
  contenedor: {
    display: "table",
    width: "100%",
  },
  tabla: {},
  fila: {
    flexDirection: "row",
  },
  aliasProveedor: {
    fontSize: 6,
    marginTop: 2,
  },
  codesView: {
    flexDirection: "row",
    minHeight: "10%",
    maxHeight: "10%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    "white-space": "nowrap",
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
  firstView: {
    flexDirection: "row",
    minHeight: "20%",
    maxHeight: "20%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    "white-space": "nowrap",
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
  viewAuxProveedor: {
    flexDirection: "row",
    minHeight: "45%",
    maxHeight: "45%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    "white-space": "nowrap",
    display: "flex",
    alignItems: "center",
  },
  viewAuxProveedorVertical: {
    flexDirection: "row",
    minHeight: "20%",
    maxHeight: "20%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    "white-space": "nowrap",
    display: "flex",
    alignItems: "center",
  },
  viewBarra: {
    // marginBottom: -1,
    width: "100%",
    // height: "2rem",
    // margin: "auto",
    // display: "flex",
    maxWidth: "100%",
    // maxHeight: "1rem",
    // height: "1rem",
    // height: "10%",
    // maxHeight: "10%",
    minHeight: "40%",
    maxHeight: "40%",
    marginBottom: 4,
    // flexDirection: "row",
    // justifyContent: "center",
  },
  viewLogoCodigoHorizontal: {
    flexDirection: "row",
    minHeight: "100%",
    maxHeight: "100%",
    display: "flex",
    alignItems: "center",
  },
  viewLogoVertical: {
    flexDirection: "row",
    minHeight: "25%",
    maxHeight: "25%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    "white-space": "nowrap",
    display: "flex",
    alignItems: "center",
  },
  viewCodigoNumeroVertical: {
    flexDirection: "row",
    minHeight: "10%",
    maxHeight: "10%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    "white-space": "nowrap",
    display: "flex",
    alignItems: "center",
  },
  viewCodigoNumeroHorizontal: {
    flexDirection: "row",
    minHeight: "100%",
    maxHeight: "100%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    "white-space": "nowrap",
    display: "flex",
    alignItems: "center",
  },
  viewCodigoNumeroHorizontal50: {
    flexDirection: "row",
    maxHeight: "50%",
    width: "50%",
    display: "flex",
    alignItems: "center",
    marginTop: -10,
    marginBottom: -5,
  },
  h1: {
    fontSize: 24,
    fontWeight: 425,
    bottom: 20,
    left: 45,
    textTransform: "uppercase",
  },
  h5: {
    fontSize: 12,
    fontWeight: 500,
    textTransform: "uppercase",
  },
  h6: {
    fontSize: 10,
    fontWeight: 500,
    textTransform: "uppercase",
  },
  body1: {
    fontSize: 9,
    lineHeight: 1.5,
    textTransform: "uppercase",
  },
  body2: {
    fontSize: 7.5,
    lineHeight: 1.5,
    textTransform: "uppercase",
    "white-space": "nowrap",
  },
});

export const textAlias = (alias) => {
  let newAlias = alias.slice(0, 12);
  return (
    <Text style={{ fontSize: 9, fontWeight: "bold", marginLeft: 3 }}>
      {newAlias.toUpperCase()}
    </Text>
  );
};

export const textArticulo = (nombre) => {
  return (
    <Text
      style={{
        fontSize: 7,
        marginTop: 2,
      }}
    >
      {nombre.toUpperCase()}
    </Text>
  );
};

export const textCodigoArticulo = (codigo) => {
  return (
    <Text
      style={{
        fontSize: 5,
        marginTop: 2,
      }}
    >
      {codigo.toUpperCase()}
    </Text>
  );
};

export const codBarra = (pngUrl) => {
  return <Image src={pngUrl} />;
};

export const textAuxiliar = (auxiliar) => {
  return (
    <Text
      style={{
        fontSize: 7,
        marginTop: 2,
        marginLeft: 3,
      }}
    >
      {auxiliar.toUpperCase()}
    </Text>
  );
};

export const logoSucursal = (pngUrl) => {
  return (
    <Image
      src={pngUrl && `data:image/png/jpg;base64,${pngUrl}`}
      style={{ width: "65%" }}
    />
  );
};

export const textProveedor = (auxiliar) => {
  return (
    <Text
      style={{
        fontSize: 9,
        fontWeight: "bold",
        marginLeft: 3,
      }}
    >
      {auxiliar.toUpperCase()}
    </Text>
  );
};

export const viewCodBarra = (pngUrl) => {
  return <View style={styles.viewBarra}>{codBarra(pngUrl)}</View>;
};

export const viewLogoCodigoHorizontal = (imageSucursal, codigo) => {
  return (
    <View style={{ flexDirection: "row", minHeight: "25%", maxHeight: "25%" }}>
      <View style={{ flexDirection: "column", flex: 1 }}>
        <View style={styles.viewLogoCodigoHorizontal}>
          <View
            style={{
              flexDirection: "column",
              flex: 2,
              alignItems: "center",
            }}
          >
            {logoSucursal(imageSucursal)}
          </View>
        </View>
      </View>
      <View style={{ flexDirection: "column", flex: 1 }}>
        <View style={styles.viewCodigoNumeroHorizontal}>
          <View
            style={{
              flexDirection: "column",
              flex: 1,
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontSize: 8,
              }}
            >
              {codigo.toUpperCase()}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export const viewLogoCodigoVertical = (imageSucursal, codigo) => {
  return (
    <>
      <View style={styles.viewCodigoNumeroVertical}>
        <View
          style={{
            flexDirection: "column",
            flex: 1,
            textAlign: "center",
          }}
        >
          <Text
            style={{
              fontSize: 8,
            }}
          >
            {codigo.toUpperCase()}
          </Text>
        </View>
      </View>
      <View style={styles.viewLogoVertical}>
        <View
          style={{
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
          }}
        >
          {logoSucursal(imageSucursal)}
        </View>
      </View>
    </>
  );
};

// Nuevos componentes
export const mostrarUbicacionArticulo = (repuestoSucursal, fontSize = 4) => {
  if (!repuestoSucursal) return null;

  return (
    <View
      style={{
        marginBottom: 2,
        width: "100%",
        display: "flex",
        "white-space": "nowrap",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {repuestoSucursal.deposito ? repuestoSucursal.deposito.descripcion : ""}
        {repuestoSucursal.zona ? ` - ${repuestoSucursal.zona.descripcion}` : ""}
        {repuestoSucursal.estante
          ? ` - ${repuestoSucursal.estante.descripcion}`
          : ""}
        {repuestoSucursal.fila ? ` - ${repuestoSucursal.fila.descripcion}` : ""}
      </Text>
    </View>
  );
};

export const mostrarPrecioArticulo = (precioArticulo, fontSize = 4) => {
  return (
    // <View
    //   style={{
    //     marginTop: -1,
    //     width: "100%",
    //     display: "flex",
    //     "white-space": "nowrap",
    //     flexDirection: "column",
    //     justifyContent: "center",
    //   }}
    // >
    <View
      style={{
        width: "50%",
        display: "flex",
        marginBottom: 2,
        "white-space": "nowrap",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          fontWeight: "bold",
          textAlign: "center",
          textOverflow: "ellipsis",
        }}
      >
        {precioArticulo}
      </Text>
    </View>
  );
};

export const mostrarCodigo = (codigo = "---", fontSize = 4) => {
  return (
    <View
      style={{
        marginBottom: 1,
        width: "100%",
        display: "flex",
        "white-space": "nowrap",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          fontWeight: "bold",
          textAlign: "center",
          textOverflow: "ellipsis",
        }}
      >
        {codigo}
      </Text>
    </View>
  );
};

export const mostrarDescripcionArticulo = (descripcion, fontSize = 4) => {
  return (
    <View
      style={{
        marginBottom: 2,
        width: "100%",
        display: "flex",
        "white-space": "nowrap",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          fontWeight: "bold",
          textAlign: "center",
          textOverflow: "ellipsis",
        }}
      >
        {descripcion.length > 55
          ? `${descripcion.slice(0, 55)}...`
          : descripcion}
      </Text>
    </View>
  );
};

export const mostrarNombreProveedor = (nombreProveedor, fontSize = 4) => {
  return (
    <View
      style={{
        marginBottom: 2,
        width: "100%",
        display: "flex",
        "white-space": "nowrap",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          fontWeight: "bold",
          textAlign: "center",
          textOverflow: "ellipsis",
        }}
      >
        {nombreProveedor}
      </Text>
    </View>
  );
};

export const mostrarLogoSucursal = (imagenSucursal) => {
  return (
    <View style={styles.viewCodigoNumeroHorizontal50}>
      <Image
        style={{ width: "100%", objectFit: "contain" }}
        src={imagenSucursal && `data:image/png/jpg;base64,${imagenSucursal}`}
      />
    </View>
  );
};

export const mostrarNombreSucursal = (nombreSucursal, fontSize = 4) => {
  return (
    <View
      style={{
        width: "50%",
        display: "flex",
        marginTop: -10,
        marginBottom: -5,
        "white-space": "nowrap",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          fontWeight: "bold",
          textAlign: "center",
          textOverflow: "ellipsis",
        }}
      >
        {nombreSucursal}
      </Text>
    </View>
  );
};

export const mostrarFechaImpresion = (fontSize = 4) => {
  return (
    // <View
    //   style={{
    //     marginBottom: 2,
    //     width: "100%",
    //     display: "flex",
    //     "white-space": "nowrap",
    //     flexDirection: "column",
    //     justifyContent: "center",
    //   }}
    // >
    <View
      style={{
        width: "50%",
        display: "flex",
        marginBottom: 2,
        "white-space": "nowrap",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          fontSize: fontSize,
          fontWeight: "bold",
          textAlign: "center",
          textOverflow: "ellipsis",
        }}
      >
        {moment().format("MM-YYYY")}
      </Text>
    </View>
  );
};
