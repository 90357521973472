import React from "react";
import { Checkbox, Grid, Typography } from "@material-ui/core";

export default function DetallesAMostrar({ formik }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          className="mb-2"
          style={{ fontSize: "1.5rem", color: "gray" }}
        >
          Detalles a mostrar
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>Logotipo del negocio</Typography>
        <Checkbox
          name="logotipo"
          color="primary"
          onChange={formik.handleChange}
          checked={formik.values.logotipo}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>Código proveedor</Typography>
        <Checkbox
          name="codigoProveedor"
          color="primary"
          onChange={formik.handleChange}
          checked={formik.values.codigoProveedor}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>Código original</Typography>
        <Checkbox
          name="codigoOriginal"
          color="primary"
          onChange={formik.handleChange}
          checked={formik.values.codigoOriginal}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>Código auxiliar</Typography>
        <Checkbox
          name="codigoAuxiliar"
          color="primary"
          onChange={formik.handleChange}
          checked={formik.values.codigoAuxiliar}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>Nombre del proveedor</Typography>
        <Checkbox
          name="nombreProveedor"
          color="primary"
          onChange={formik.handleChange}
          checked={formik.values.nombreProveedor}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>Nombre del negocio</Typography>
        <Checkbox
          color="primary"
          name="nombreNegocio"
          onChange={formik.handleChange}
          checked={formik.values.nombreNegocio}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>Ubicación </Typography>
        <Checkbox
          name="ubicacion"
          color="primary"
          onChange={formik.handleChange}
          checked={formik.values.ubicacion}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>Precio</Typography>
        <Checkbox
          name="precio"
          color="primary"
          checked={formik.values.precio}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>Descripción</Typography>
        <Checkbox
          color="primary"
          name="descripcion"
          onChange={formik.handleChange}
          checked={formik.values.descripcion}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>Fecha de impresión</Typography>
        <Checkbox
          color="primary"
          name="fechaImpresion"
          onChange={formik.handleChange}
          checked={formik.values.fechaImpresion}
        />
      </Grid>
    </Grid>
  );
}
