import React from "react";
import JsBarcode from "jsbarcode";
import { Page, View, Document } from "@react-pdf/renderer";
import {
  styles,
  viewCodBarra,
  mostrarLogoSucursal,
  mostrarNombreSucursal,
  mostrarNombreProveedor,
  mostrarDescripcionArticulo,
  mostrarCodigo,
  mostrarPrecioArticulo,
  mostrarUbicacionArticulo,
  mostrarFechaImpresion,
} from "../Reutilizables";

export default function EtiquetasCarta({
  articulo,
  sucursal,
  filasArray,
  columnasArray,
  configGeneral,
  configuracionEtiqueta,
}) {
  // Configuracion de detalles
  const handleMostrarPrecioArticulo = () => {
    let precio;

    switch (configuracionEtiqueta.precioAMostrar) {
      case "venta":
        precio = articulo.precios.venta;
        break;
      case "costo":
        precio = articulo.precios.costo;
        break;
      case "lista":
        precio = articulo.precios.lista;
        break;
    }

    if (configuracionEtiqueta.cifrarPrecio) {
      precio = handleCifrarPrecio(precio);
    } else {
      precio = parseInt(precio).toLocaleString("ES-ar");
    }

    return precio;
  };

  const handleCifrarPrecio = (precio) => {
    const mapeo = {
      1: "E",
      2: "C",
      3: "L",
      4: "U",
      5: "Y",
      6: "I",
      7: "N",
      8: "T",
      9: "A",
      0: "R",
      ".": "/",
    };

    const precioCifrado = precio
      .toString()
      .split("")
      .map((caracter) => mapeo[caracter] || caracter)
      .join("");

    return precioCifrado;
  };

  const handleGenerarCodigoBarras = () => {
    switch (configuracionEtiqueta.codigoBarrasDesde) {
      case "proveedor":
        return articulo.codProveedor ? articulo.codProveedor : "000";
      case "original":
        return articulo.codOriginal ? articulo.codOriginal : "000";
      case "auxiliar":
        return articulo.codAuxiliar ? articulo.codAuxiliar : "000";
      default:
        return "---";
    }
  };

  const handleMostrarCodigoDeBarras = () => {
    // Si el codigo seleccionado existe en el articulo muestra el codig ode barras
    switch (configuracionEtiqueta.codigoBarrasDesde) {
      case "proveedor":
        return articulo.codProveedor ? true : false;
      case "original":
        return articulo.codOriginal ? true : false;
      case "auxiliar":
        return articulo.codAuxiliar ? true : false;
      default:
        return false;
    }
  };
  // Configuracion de detalles

  // COnfiguracion Barcode
  JsBarcode("#barcode", handleGenerarCodigoBarras(), {
    lineColor: "#000",
    width: 4,
    height: 80,
    displayValue: false,
  });

  const canvas = document.getElementById("barcode");
  const pngUrl = canvas.toDataURL("image/png");
  // COnfiguracion Barcode

  return (
    //LETTER = CARTA => 612px/220mm(ancho) x 792px/280mm(alto)
    <Document title={`Etiqueta${columnasArray.cant}x${filasArray.cant}CARTA`}>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.contenedor}>
          <View style={styles.tabla}>
            {filasArray.array.map((x, iF) => (
              <View style={styles.fila} key={iF}>
                {columnasArray.array.map((x, iC) => (
                  <View
                    style={{
                      width: `${100 / columnasArray.cant}%`,
                      height: `${792.0 / filasArray.cant}`,
                      maxHeight: `${792.0 / filasArray.cant}`,
                      padding: 5,
                    }}
                    key={iC}
                  >
                    <View
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {/* 50% */}
                      {configuracionEtiqueta.logotipo
                        ? mostrarLogoSucursal(configGeneral.url_imagen)
                        : null}
                      {/* 50% */}

                      {/* 50% */}
                      {configuracionEtiqueta.nombreNegocio
                        ? mostrarNombreSucursal(
                            sucursal.razonSocial,
                            configuracionEtiqueta.tamanioTipografia,
                          )
                        : null}
                      {/* 50% */}
                    </View>

                    {/* 100% */}
                    {configuracionEtiqueta.nombreProveedor
                      ? mostrarNombreProveedor(
                          articulo.proveedor,
                          configuracionEtiqueta.tamanioTipografia,
                        )
                      : null}
                    {/* 100% */}

                    {/* 100% */}
                    {configuracionEtiqueta.descripcion
                      ? mostrarDescripcionArticulo(
                          articulo.descripcionProveedor,
                          configuracionEtiqueta.tamanioTipografia,
                        )
                      : null}
                    {/* 100% */}

                    {/* 100% */}
                    {configuracionEtiqueta.codigoProveedor
                      ? mostrarCodigo(
                          articulo.codProveedor,
                          configuracionEtiqueta.tamanioTipografia,
                        )
                      : null}
                    {/* 100% */}

                    {/* 100% */}
                    {configuracionEtiqueta.codigoOriginal
                      ? mostrarCodigo(
                          articulo.codOriginal,
                          configuracionEtiqueta.tamanioTipografia,
                        )
                      : null}
                    {/* 100% */}

                    {/* 100% */}
                    {configuracionEtiqueta.codigoAuxiliar
                      ? mostrarCodigo(
                          articulo.codAuxiliar,
                          configuracionEtiqueta.tamanioTipografia,
                        )
                      : null}
                    {/* 100% */}

                    {/* 100% */}
                    {configuracionEtiqueta.ubicacion
                      ? mostrarUbicacionArticulo(
                          articulo.repuesto_sucursal,
                          configuracionEtiqueta.tamanioTipografia,
                        )
                      : null}
                    {/* 100% */}

                    <View
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {/* 50% */}
                      {configuracionEtiqueta.precio
                        ? mostrarPrecioArticulo(
                            handleMostrarPrecioArticulo(),
                            configuracionEtiqueta.tamanioTipografia,
                          )
                        : null}
                      {/* 50% */}

                      {/* 50% */}
                      {configuracionEtiqueta.fechaImpresion
                        ? mostrarFechaImpresion(
                            configuracionEtiqueta.tamanioTipografia,
                          )
                        : null}
                      {/* 50% */}
                    </View>

                    {/* 100% */}
                    {handleMostrarCodigoDeBarras()
                      ? viewCodBarra(pngUrl)
                      : null}
                    {/* 100% */}
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
}
